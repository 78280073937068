:root {
    --landing-page-background: url('./images/waves.gif');
    --gradient-color-1: #3a3a3a;
    --gradient-color-2: #006699;
  }
  /*
  .landing-page {
    background-size: cover;
    height: 100vh;
    display: flex;
    flex-direction: column;
     }
*/

    .landing-page {
      display: grid;
      grid-template-columns: 1fr; /* Single column layout */
      grid-template-rows: auto;
      justify-content: center;
      align-items: start; /* Ensures panels align from the top */
      background-color: #0000;
      color: white;
      margin-top: 10vh;
      padding: 20px;
      width: 95%;
    }
 
  
  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1rem;
  }
  
  .header h1 {
    margin: 0;
    color: #ffffff;
    font-size: 2rem;
  }
  
  .header ul {
    list-style-type: none;
    margin: 0;
    padding: 0;
    display: flex;
  }
  
  .header li {
    margin-right: 1rem;
  }
  
  .header a {
    color: #ffffff;
    text-decoration: none;
    font-size: 1.2rem;
    
  }

  .header a:hover
  {

    text-shadow: 0 0 10px #ffffff, 0 0 5px #ffffff, 0 0 5px #ffffff, 0 0 5px #ffffff; /* Add this line to enhance the glow effect on hover */
 
  }
  
  .main-content {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

  }

  .overlay-image {
    position: fixed;
  top: -75px;
  left: 8px;
  width: 250px;
  height: auto;
  object-fit: cover;
  object-position: center;
  
  opacity: 0.5;
  z-index: -2;

  }
  
  .main-content h2 {
    color: #ffffff;
    font-size: 2rem;
    margin-bottom: 1rem;
    position: relative;
    z-index: 1;
  }
  
  .main-content p {
    color: #ffffff;
    font-size: 1.2rem;
    text-align: center;
    margin-bottom: 2rem;

  }
  
  .cta-button {
    background-color: #ffd569;
    color: #000000;
    font-size: 1.2rem;
    padding: 1rem 2rem;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.5s ease-in-out;
    transition: box-shadow 0.4s ease-out;  

  }
  
  .cta-button:hover {
    background-color: #efc14c;
    box-shadow: 0 0 10px #ffffff, 0 0 5px #ffffff, 0 0 5px #ffffff, 0 0 5px #ffffff; /* Add this line to enhance the glow effect on hover */
    transition: box-shadow 0.01s ease-in;
}



/* Title Panel */
.title-panel {
  text-align: center;
}

/* Universal Panel Styling */
.promotion-panel,
.filter-panel,
.content-panel {
  background: rgba(0, 0, 0, 0.5); /* Black background for panels */
  border: 3px solid #222; /* Dark beveled border */
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 1); /* Drop shadow */
  border-radius: 3px;
  padding: 15px;
  margin: 10px auto;
  width: 90%; /* Ensures panels fit within screen width */
}

/* Promotion Panel */
.promotion-panel {
  width: 90%;
  overflow: hidden;
}

.promo-image {
  width: 100%;
  border-radius: 10px;
  height: auto; /* Maintain aspect ratio */
  transition: opacity 1s ease-in-out;
}

.title-panel h1 {
  font-size: 2.5rem;
  font-style: italic;
  color: gold;
  text-shadow: 2px 2px 5px rgba(0, 0, 0, 0.7);
  padding: 10px;
}

/* Filter Buttons - Responsive Layout */
.filter-panel {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 10px;
  padding: 10px;
}

.filter-panel button {
  background-color: #222;
  color: white;
  border: 1px solid gold;
  padding: 10px 15px;
  cursor: pointer;
  border-radius: 5px;
  transition: all 0.3s ease;
  font-size: 1rem;
}

.filter-panel button:hover {
  background-color: gold;
  color: black;
  transform: scale(1.05);
}

/* Content Panel */
.content-panel {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start; /* Align thumbnails to the left */
  gap: 15px; /* Maintain consistent spacing */
  padding: 20px;
}

/* Content Items */
.content-item {
  text-decoration: none;
  color: white;
  text-align: center;
  background: #111;
  border: 2px solid #444;
  border-radius: 10px;
  padding: 10px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.7);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  width: auto;
  max-width: 200px;

}

.content-thumbnail {
  width: 100%;
  height: auto;
  border-radius: 5px;
}

.content-item:hover {
  transform: scale(1.05);
  box-shadow: 0px 6px 12px rgba(255, 215, 0, 0.5); /* Gold glow effect */
}

/* Fade-in and Fade-out */
.fade-in {
  opacity: 1;
  z-index: 2;
}

.fade-out {
  opacity: 0;
  z-index: 1;
}
/* ⚡ MOBILE FRIENDLY STYLES ⚡ */
@media (max-width: 768px) {
  .title-panel h1 {
    font-size: 2rem; /* Reduce title size */
  }

  .filter-panel {
    flex-direction: column; /* Stack filters vertically */
    align-items: center;
  }

  .filter-panel button {
    width: 80%;
    text-align: center;
  }

  .content-panel {
    grid-template-columns: repeat(auto-fit, minmax(130px, 1fr)); /* Adjust for mobile */
  }

  .promotion-panel {
    width: 90%;
  }

  .promo-image {
    width: 100%;
  }
}