:root {
  --landing-page-background: url('./images/waves.gif');
  --gradient-color-1: #3a3a3a;
  --gradient-color-2: #006699;
}

.backdrop {
position: fixed;
background: linear-gradient(to bottom, var(--gradient-color-1), var(--gradient-color-2)), var(--landing-page-background);
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -3;
}

.app-page {
  display: flex;
  flex-direction: column;

}

.spacer {
  margin: 20px; /* Adds space around the div */
  height: 10px;  /* Adjust height as needed */
  width: 100%;   /* Full width */
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem;
}

.header h1 {
  margin: 0;
  color: #ffffff;
  font-size: 2rem;
}

.header ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
  display: flex;
}

.header li {

  margin-right: 1rem;
}

.header button {

  color: #ffffff;
  background: none;
  text-decoration: none;
  border: none;
  font-size: 1.2rem;
  
}

.header button:hover
{

  text-shadow: 0 0 10px #ffffff, 0 0 5px #ffffff, 0 0 5px #ffffff, 0 0 5px #ffffff; /* Add this line to enhance the glow effect on hover */

}

.main-content {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

}

.overlay-image {
  position: absolute;
top: -50px;
left: 8px;
width: 250px;
height: auto;
object-fit: cover;
object-position: center;

opacity: 0.5;
z-index: -2;

}

.overlay-image2 {
  position: absolute;
bottom: -300px;
right: -300px;
width: 1000px;
height: auto;
object-fit: cover;
object-position: center;

opacity: 0.05;
z-index: -2;

}

.logo-image
{
  position: absolute;
  top: 35px;
left: 10px;
width: 300px;
height: auto;
object-fit: cover;
object-position: center;

}



.main-content h1 {
  color: #ffffff;
  font-size: 1.5rem;
  margin-bottom: 1rem;
  position: relative;
  z-index: 1;
}

.main-content h2 {
  color: #ffffff;
  font-size: 1.3rem;
  margin-bottom: 1rem;
  position: relative;
  z-index: 1;
}

.main-content p {
  color: #ffffff;
  font-size: 1rem;
  text-align: center;
  margin-bottom: 2rem;
  margin-left: 5rem;
  margin-right: 5rem;

}

.cta-button {
  background-color: #ffd569;
  color: #000000;
  font-size: 1.2rem;
  padding: 1rem 2rem;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.5s ease-in-out;
  transition: box-shadow 0.4s ease-out;  

}

.cta-button:hover {
  background-color: #efc14c;
  box-shadow: 0 0 10px #ffffff, 0 0 5px #ffffff, 0 0 5px #ffffff, 0 0 5px #ffffff; /* Add this line to enhance the glow effect on hover */
  transition: box-shadow 0.01s ease-in;
}

.centered-image {
 
  width: auto;
  height: auto;
  object-fit: cover;
  object-position: center;

}